import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Container from '../components/common/container';
import Footer from '../components/common/footer';
import BlockContent from '../components/portable-text';

const Hero = styled.div`
  background: linear-gradient(180deg, #2d3861, rgba(2, 14, 58, 0.92) 50%);
  position: relative;
  padding: 84px;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    padding: 32px 16px;
  }

  h2 {
    font-weight: 400;
    line-height: 1.5;
  }
`;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 690px;
  margin: 0 auto;

  @media screen and (min-width: 768px) {
    padding-top: 120px;
  }
`;

const Title = styled.h1`
  color: white;
  font-size: 40px;
  font-weight: 800;
  line-height: 50px;
  margin-bottom: 0;

  @media screen and (min-width: 768px) {
    font-size: 64px;
    line-height: 80px;
  }
`;

const SubTitle = styled.h2`
  color: rgba(255, 255, 255, 0.9);
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 32px;
  margin-top: 16px;
  font-weight: 400;

  @media screen and (min-width: 768px) {
    font-size: 24px;
  }
`;

const StyledContainer = styled(Container)`
  padding-bottom: ${(props) => props.theme.space[6]}px;
  padding-top: ${(props) => props.theme.space[6]}px;
  max-width: 1080px;
  margin: 0 auto;

  @media screen and (min-width: 768px) {
    padding-bottom: ${(props) => props.theme.space[8]}px;
    padding-top: ${(props) => props.theme.space[8]}px;
  }

  @media screen and (max-width: 768px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: ${(props) => props.theme.space[4]}px;
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: ${(props) => props.theme.space[4]}px;

    &:last-of-type {
      margin-bottom: 0;
    }

    @media screen and (min-width: 768px) {
      font-size: 1.5rem;
      margin-bottom: ${(props) => props.theme.space[6]}px;
    }
  }

  ul {
    margin-bottom: ${(props) => props.theme.space[4]}px;
    list-style-type: disc;
    padding-left: 2rem;

    @media screen and (min-width: 768px) {
      margin-bottom: ${(props) => props.theme.space[6]}px;
    }

    li {
      line-height: 1.5;
      margin-bottom: ${(props) => props.theme.space[2]}px;

      @media screen and (min-width: 768px) {
        font-size: 1.5rem;
        margin-bottom: ${(props) => props.theme.space[4]}px;
      }
    }
  }

  h3 {
    @media screen and (max-width: 768px) {
      font-size: 2rem;
    }
  }

  strong {
    font-weight: 600;
  }

  figure {
    margin-bottom: ${(props) => props.theme.space[6]}px;
    font-size: 1.2rem;
    font-weight: 600;

    .gatsby-image-wrapper {
      margin-bottom: ${(props) => props.theme.space[4]}px;
    }
  }

  a {
    color: #000000;
    font-weight: 600;
  }
`;

const HeroImage = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const Page = ({ data }) => (
  <Layout>
    <SEO title={data.page.title} />
    <Hero>
      <Wrap>
        <Title>{data.page.title}</Title>
        {data.page.lede && <SubTitle>{data.page.lede}</SubTitle>}
      </Wrap>
      {data.page.heroImage && (
        <HeroImage fluid={data.page.heroImage.asset.fluid} />
      )}
    </Hero>

    <StyledContainer>
      <BlockContent blocks={data.page._rawBody} />
    </StyledContainer>

    <Footer />
  </Layout>
);

export const query = graphql`
  query ($slug: String) {
    page: sanityPage(slug: { current: { eq: $slug } }) {
      lede
      title
      _rawBody
      # heroImage {
      #  asset {
      #    fluid(maxWidth: 1280) {
      #      ...GatsbySanityImageFluid
      #    }
      #  }
      # }
    }
  }
`;

export default Page;
